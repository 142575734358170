

import React, { useState, useEffect } from 'react';
import AnimatedBoxEN from '../../assets/paskopanalo/ClosedBoxPH.png';
import AnimatedBoxKROpen from '../../assets/paskopanalo/OpenBoxPH.png';
import AnimatedBoxKR from '../../assets/paskopanalo/ClosedBoxPH.png';
import PotMoney from '../../assets/paskopanalo/PrizePoolPH.png'
import TermsAndCondition from '../../components/popup/pasko.panalo/TermsAndCondition';
import Suprise from '../../components/popup/pasko.panalo/OpenBox';
import BackgroundVideo from '../../assets/paskopanalo/BackgroundPH.mp4';
import BackgroundVideo2 from '../../assets/paskopanalo/BackgroundPHMob.mp4';
import OpenBox from '../../components/popup/pasko.panalo/OpenBox';
import PotMoneyModal from '../../components/popup/pasko.panalo/PotMoney';
import Login from '../../components/popup/pasko.panalo/Login';
import { useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image';
import { color } from 'framer-motion';
import '../../style/paskopanalo/style-paskopanalo.css'
import md5 from 'crypto-js/md5';

function Home({ user, setUser }) {
  const { t, i18n } = useTranslation();
  const [haveAvailableClaims, setHaveAvailableClaims] = useState(true);
  const [showPotMoney,setShowPotMoney] = useState(false); 
  const [showTerms, setShowTerms] = useState(false);
  const [showSup, setShowSup] = useState(false);
  const [showOpenBox, setShowOpenBox] = useState(false);
  const [currentGameAsset, setCurrentGameAsset] = useState(AnimatedBoxEN);
  const [originalGameAsset, setOriginalGameAsset] = useState(AnimatedBoxEN);
  const [showLogin, setShowLogin] = useState(false); // Control the display of the login popup

  // Check localStorage on mount
  useEffect(() => {
    const claimsData = localStorage.getItem('have_available_claims');
    if (claimsData !== null) {
      setHaveAvailableClaims(JSON.parse(claimsData)); // Parse the boolean value
    }
  }, []);

 // Pot Money States
 const [potMoney, setPotMoney] = useState(null);
 const [loadingPotMoney, setLoadingPotMoney] = useState(false);
 const [errorPotMoney, setErrorPotMoney] = useState(null);

 // Fetch the token and pot money
 useEffect(() => {
   const fetchPotMoney = async () => {
     setLoadingPotMoney(true);
     setErrorPotMoney(null);

     try {
       // Step 1: Fetch token
       const tokenResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/get-token`,
         {
           method: 'POST',
           headers: { 'Content-Type': 'application/json' },
           body: JSON.stringify({
            email: `${process.env.REACT_APP_EMAIL}`,
            password: `${process.env.REACT_APP_PASSWORD}`,
            event_id: `${process.env.REACT_APP_EVENT_ID}`,
            event_name: `${process.env.REACT_APP_EVENT_NAME}`,
             payload: generatePayload()
           }),
         }
       );

       const payloadData = {
        email: `${process.env.REACT_APP_EMAIL}`,
        password: `${process.env.REACT_APP_PASSWORD}`,
        event_id: `${process.env.REACT_APP_EVENT_ID}`,
        event_name: `${process.env.REACT_APP_EVENT_NAME}`,
        payload: generatePayload()
      };

       const tokenData = await tokenResponse.json();
       if (!tokenData.status) {
         throw new Error(tokenData.message || 'Failed to fetch token');
       }


       const { token, event_reference_code } = tokenData;

       // Step 2: Fetch pot money using the token and reference code
       const potMoneyResponse = await fetch(
         `${process.env.REACT_APP_API_URL}api/v1/luckybox/get-pot-money`,
         {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`,
           },
           body: JSON.stringify({
             event_reference_code,
             currency: 'PHP',
             event_id: 1,
           }),
         }
       );

       const potMoneyData = await potMoneyResponse.json();
       if (!potMoneyData.status) {
         throw new Error(potMoneyData.message || 'Failed to fetch pot money');
       }

       setPotMoney(potMoneyData.pot_money);
     } catch (err) {
       setErrorPotMoney(err.message || 'An error occurred');
     } finally {
       setLoadingPotMoney(false);
     }
   };

   fetchPotMoney();
 }, []); // Empty dependency array means this will run once on mount


 const generatePayload = () => {
  const email = process.env.REACT_APP_EMAIL;
  const password = process.env.REACT_APP_PASSWORD;
  const eventId = process.env.REACT_APP_EVENT_ID;
  const eventName = process.env.REACT_APP_EVENT_NAME;
  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const payloadString = `${email}${password}${eventId}${eventName}${secretKey}`;
  return md5(payloadString).toString();
};





  useEffect(() => {
    if (i18n.language === 'kr') {
      setCurrentGameAsset(AnimatedBoxKR);
      setOriginalGameAsset(AnimatedBoxKR);
    } else {
      setCurrentGameAsset(AnimatedBoxEN);
      setOriginalGameAsset(AnimatedBoxEN);
    }
  }, [i18n.language]);

  const handleShowOpenBox = () => {
    
    if (!user) {
      setShowLogin(true);
    } else {
      setCurrentGameAsset(AnimatedBoxKROpen);
      setTimeout(() => {
        setShowOpenBox(true);
      }, 1000);
      
    }
  };

  const handleCloseOpenBox = () => {
    setShowOpenBox(false);
    setCurrentGameAsset(originalGameAsset);
    };

  const handleShowTerms = () => setShowTerms(true);
  const handleCloseTerms = () => setShowTerms(false);

  const handleShowPotMoney = () => setShowPotMoney(true);
  const handleClosePotMoney = () => setShowPotMoney(false);

  const handlePotMoneyClick = () => {
    handleShowPotMoney(); 
  };

  // const handleShowSup = () => {
  //   if (!user) {
  //     setShowLogin(true);
  //   } else {
  //     setCurrentGameAsset(AnimatedBoxKROpen);
  //     setTimeout(() => {
  //       setShowSup(true);
  //     }, 1000);
      
  //     // setCurrentGameAsset(i18n.language === 'kr' ? AnimatedBoxKR : AnimatedBoxEN); 
  //   }
  // };



  // const handleCloseSup = () => {
  //   setShowSup(false);
  //   setCurrentGameAsset(originalGameAsset); 
  // };

  const handleGameAssetClick = () => {
    handleShowOpenBox();
  };

  const handleTermsLinkClick = () => {
    handleShowTerms(); 
  };

 

  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  return (
    <div className='appBox'>
      <video autoPlay muted loop playsInline className='video-background'>
        <source src={BackgroundVideo} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <video autoPlay muted loop playsInline className='mobile-video-background'>
        <source src={BackgroundVideo2} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <div className='container clearfix'>
      <div className='pot-money'>
          {loadingPotMoney && <p>Loading...</p>}
          {errorPotMoney && <p>Error: {errorPotMoney}</p>}
          {potMoney !== null && (
            <p>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'PHP',
                minimumFractionDigits: 2,
              }).format(potMoney)}
            </p>
          )}
        <Image 
          src={PotMoney} 
          alt="Pot Money" onClick={handlePotMoneyClick}/>
        </div>
        <p dangerouslySetInnerHTML={{ __html: t('100chance') }} className="chance-100-ph"></p>
        
        
        
        <div className='game-assets' onClick={handleGameAssetClick}>
        <Image 
          src={currentGameAsset} 
          alt="Lucky Box" />
        </div>


        {/* <div className='game-btn'> */}
          {/* <div onClick={handleShowSup} className='btn-dsgn'><button> */}
          {/* <div className='game-assets' onClick={handleGameAssetClick}>
        <Image 
          src={currentGameAsset} 
          alt="Lucky Box" />
         */}
          {/* <div onClick={handleShowSup} className='btn-dsgn'><button> */}
          {/* <div className='btn-dsgn'><button>
            
            
            {haveAvailableClaims ? t('clicksurprise') : t('alreadyclaimed')}
          
            
            </button></div></div> */}



        <div className='game-btn'>
          {/* <div onClick={handleShowSup} className='btn-dsgn'><button> */}
          <div onClick={handleGameAssetClick} className='btn-dsgn'><button>
            
            
            {haveAvailableClaims ? t('clicksurprise') : 'Check claimed prize'}
          
            
            </button></div>
          <a href='#' onClick={handleTermsLinkClick}>{t('termsandconditions')}</a>
        </div>
        <PotMoneyModal show={showPotMoney} handleClose={handleClosePotMoney} />
        <OpenBox show={showOpenBox} handleClose={handleCloseOpenBox} username={user?.name} />
        <TermsAndCondition show={showTerms} handleClose={handleCloseTerms} />
        {/* <Suprise show={showSup} handleClose={handleCloseSup} username={user?.name} /> */}
        <Login show={showLogin} handleClose={handleCloseLogin} setUser={setUser} />
      </div>
    </div>
  );
}


export default Home;
