import React, { useState, useEffect } from 'react';
import './App.css';
import './style/paskopanalo/style-paskopanalo.css';
import Navbar from './components/navbar/pasko.panalo/Navbar';
import Home from './pages/pasko.panalo/Home';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Preloader from './components/preloader/paskopanalo/Preloader';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import MaintenancePage from './components/maintenance/MaintenancePage'; // Import MaintenancePage
import md5 from 'crypto-js/md5';

const AppContent = ({ user, setUser }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Navbar user={user} setUser={setUser} />
      <Routes>
        <Route path='/:lng' element={<Home user={user} setUser={setUser} />} />
        <Route path='/' element={<Home user={user} setUser={setUser} />} />
        
      </Routes>
    </>
  );
};

function App() {
  const [isMaintenance, setIsMaintenance] = useState(false); // State for maintenance status
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  // Fetch token and check maintenance status
  const fetchTokenAndCheckMaintenance = async () => {
    try {
      const tokenResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/get-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: process.env.REACT_APP_EMAIL,
          password: process.env.REACT_APP_PASSWORD,
          event_id: process.env.REACT_APP_EVENT_ID,
          event_name: process.env.REACT_APP_EVENT_NAME,
          payload: generatePayload(),
        }),
      });

      const tokenData = await tokenResponse.json();
      if (tokenData.status) {
        const token = tokenData.token;
        // Check the maintenance status after getting the token
        const maintenanceResponse = await fetch(`${process.env.REACT_APP_API_URL}api/v1/luckybox/get-server-maintenance-status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            event_id: 1, // Modify this based on your event ID
          }),
        });

        const maintenanceData = await maintenanceResponse.json();
        // If the status is true (server available), set isMaintenance to false, otherwise set it to true
        setIsMaintenance(maintenanceData.status === false); // false means maintenance mode is active
      }
    } catch (error) {
      console.error('Error during token fetch or maintenance check', error);
      setIsMaintenance(true); // If there’s an error, assume it's maintenance mode
    }
  };

  // Generate payload for token request
  const generatePayload = () => {
    const email = process.env.REACT_APP_EMAIL;
    const password = process.env.REACT_APP_PASSWORD;
    const eventId = process.env.REACT_APP_EVENT_ID;
    const eventName = process.env.REACT_APP_EVENT_NAME;
    const secretKey = process.env.REACT_APP_SECRET_KEY;

    const payloadString = `${email}${password}${eventId}${eventName}${secretKey}`;
    return md5(payloadString).toString();
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      fetchTokenAndCheckMaintenance(); // Fetch token and check maintenance on mount
    }, 2000); // Set a shorter timeout for the loading state
  }, []);

  if (loading) {
    return <Preloader />;
  }

  if (isMaintenance) {
    return <MaintenancePage />; // Render MaintenancePage if in maintenance mode
  }

  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <AnimatePresence exitBeforeEnter={false}>
          <motion.div
            key="contentWrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: 'easeInOut' }}
            className="contentWrapper fadeOut"
          >
            <Router>
              <AppContent user={user} setUser={setUser} />
            </Router>
          </motion.div>
        </AnimatePresence>
      </I18nextProvider>
    </div>
  );
}


export default App;
