import React from 'react';
import ReactDOM from 'react-dom/client';
import Paskopanalo from './PaskopanaloApp';
import Luckybox from './LuckyboxApp';
import md5 from 'crypto-js/md5';

// Error Boundary Component - moved to the top
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Detailed React Error:', error, errorInfo);
    
    // Optional: Send error to logging service
    // You might want to implement error tracking here
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ 
          padding: '20px', 
          backgroundColor: '#f8d7da', 
          color: '#721c24' 
        }}>
          <h1>Oops! Something went wrong.</h1>
          <p>We're experiencing technical difficulties. Please try again later.</p>
          {process.env.NODE_ENV === 'development' && (
            <pre>{this.state.error.toString()}</pre>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}

// Global error handler
window.addEventListener('error', function(event) {
  console.error('Detailed Global Error:', {
    message: event.message,
    filename: event.filename,
    lineno: event.lineno,
    colno: event.colno,
    error: event.error ? event.error.stack : 'No error stack available'
  });

  // Optional: Send error to a logging service
  if (window.trackError) {
    window.trackError(event.error);
  }
});

// Added unhandled promise rejection handler
window.addEventListener('unhandledrejection', function(event) {
  console.error('Unhandled Promise Rejection:', event.reason);
  
  // Prevent the error from being silently swallowed
  event.preventDefault();
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const appRegion = process.env.REACT_APP_REGION;

let AppComponent;

try {
  switch (appRegion) {
    case 'KR':
      AppComponent = Luckybox;
      break;
    case 'PH':
      AppComponent = Paskopanalo;
      break;
    default:
      console.error('REACT_APP_REGION is not set or is invalid. Defaulting to Paskopanalo.');
      AppComponent = Paskopanalo;
  }

  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <AppComponent />
      </ErrorBoundary>
    </React.StrictMode>
  );
} catch (error) {
  console.error('Critical Rendering Error:', error);
}

